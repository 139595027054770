
import {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
  useCallback,
}
from 'react';
import mixpanel from 'mixpanel-browser';

import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { renderDuration, createId } from '../common.js';
import { SimpleButton, CenterBox } from '../components.js';
import { Messages, Audio } from '../context.js';
import { PlayIcon, ForwardIcon, RewindIcon, PauseIcon } from '../icons.js';

const sectionPosition = (position, durations) => {
  if (isNaN(position)) return { position: 0, index: 0 };

  let index = 0;
  while (position > durations[index]
      && index < durations.length - 1) {
    position -= durations[index];
    index++;
  }
  return { position, index };
};

const AudioPlayer = forwardRef(({
      sources,
      ads,
      finalAdCount,
      preload,
      setOverrideTitle,
      contentId,
      organizationId,
      publicMode,
      sx,
    }, ref) => {

  const { setMessage } = useContext(Messages);
  const { play, stop, playing, playbackSpeed } = useContext(Audio);

  // The currently active audio segment is sources.find(s => s.sectionIndex === activeSectionIndex)
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [position, setPosition] = useState(0);
  const audioRef = useRef(null);

  // The currently active ad is ads[activeAdIndex % ads.length]
  const [activeAdIndex, setActiveAdIndex] = useState(0);
  // In publicMode, adMode is triggered when activeSectionIndex > activeAdIndex
  const [adMode, setAdMode] = useState(false);

  const id = useMemo(() => createId(), []);

  const duration = useMemo(() => sources.map(s => s.duration).sum(), [ sources ]);

  const adTimes = useMemo(() => {
    const result = [];
    for (let i = 0; i < finalAdCount; i++) {
      result.push(sources.filter(s => (s.sectionIndex ?? 0) <= i).map(s => s.duration).sum());
    }
    return result;
  }, [ sources ]);

  //useEffect(() => console.log({ sources, finalAdCount, ads, adTimes }), [sources, finalAdCount, ads, adTimes]);

  const handlePlay = useCallback((event) => {
    event?.stopPropagation();
    mixpanel.track('Play audio', {
      currentTime: position,
      contentId,
      organizationId,
    });
    play({ audioSource: audioRef.current, type: 'element', id });
  }, [id, play, contentId, organizationId, position]);

  const handleResume = useCallback(() => {
    play({ audioSource: audioRef.current, type: 'element', id });
  }, [id, play]);

  const handleSeek = useCallback((targetTime, event) => {
    if (adMode && publicMode) return;
    if (isNaN(duration)) return;

    const newTime = Math.max(Math.min(targetTime, duration), 0);

    if (event) {
      event.stopPropagation();
      mixpanel.track('Seek audio', {
        targetTime: newTime,
        contentId,
        organizationId,
      });
    }

    const { position: currentTime, index: newActiveSourceIndex } = sectionPosition(
        newTime, sources.map(s => s.duration));
    const newActiveSectionIndex = sources[newActiveSourceIndex].sectionIndex ?? 0;

    //console.log({ handleSeek: true, newTime, currentTime, newActiveSectionIndex, targetTime, duration, publicMode, activeAdIndex });

    setActiveSectionIndex(newActiveSectionIndex);
    setPosition(newTime);

    // In publicMode, we trigger adMode based on how many ads the user has viewed and what
    // section they are trying to listen to. In non-publicMode, we never trigger adMode based on
    // seeking.
    if (ads?.length > 0 && newActiveSectionIndex > activeAdIndex && publicMode) {
      // Trigger ad mode
      setAdMode(true);
    } else {
      // Jump to the selected time
      setAdMode(false);

      if (audioRef.current) {
        if (activeSectionIndex === newActiveSectionIndex) {
          // Update currentTime immediately
          audioRef.current.currentTime = currentTime;
        } else {
          // Update currentTime when the audio component has loaded the new audio
          audioRef.current.addEventListener('loadeddata', () => {
            audioRef.current.currentTime = currentTime;
          }, { once: true });
        }
      }
    }

    if (playing?.id === id) {
      setTimeout(() => handleResume(), 0);
    }
  }, [
    duration,
    activeAdIndex,
    adMode,
    id,
    playing,
    sources,
    ads,
    handleResume,
    contentId,
    organizationId,
    publicMode,
  ]);

  const handleProgress = (event) => {
    if (!adMode && event.target.currentTime !== 0) {
      //console.log({ handleProgress: true, currentTime: event.target.currentTime, adMode });
      const activeSourceIndex = sources.findIndex(s => (s.sectionIndex ?? 0) === activeSectionIndex);
      setPosition(event.target.currentTime
          + sources.slice(0, activeSourceIndex).map(s => s.duration).sum());
    }
  };

  const handlePause = () => {
    mixpanel.track('Pause audio', {
      currentTime: position,
      contentId,
      organizationId,
    });
    stop({ id });
  };

  const handleEnded = (event) => {
    let done = false;

    //console.log({ handleEnded: true, activeAdIndex, activeSectionIndex, adMode });

    const sourceIndex = sources.findIndex(s => (s.sectionIndex ?? 0) === activeSectionIndex);

    if (adMode) {
      mixpanel.track('Completed ad', {
        contentId,
        adId: ads[activeAdIndex % ads.length]?.id,
        organizationId,
      });

      const newActiveAdIndex = activeAdIndex + 1;
      setActiveAdIndex(newActiveAdIndex);

      // Check if we have heard enough ads.
      if (activeSectionIndex <= newActiveAdIndex) {
        setAdMode(false);

        // Resume at the right position in the section
        const { position: currentTime, index: newActiveSourceIndex } =
            sectionPosition(position, sources.map(s => s.duration));
        if (audioRef.current) {
          // Update currentTime when the audio component has loaded the new audio
          audioRef.current.addEventListener('loadeddata', () => {
            audioRef.current.currentTime = currentTime;
          }, { once: true });
        }
      }
    } else {
      // Advance activeSectionIndex to the next source, OR to the finalAdCount, if we are out of
      // sources.
      const newActiveSectionIndex = sourceIndex < sources.length - 1
          ? sources[sourceIndex + 1].sectionIndex ?? 0
          : finalAdCount;
      setActiveSectionIndex(newActiveSectionIndex);

      // In publicMode, a user only hears an ad at each position once. In non-publicMode, a user
      // may hear an ad at each position multiple times.
      if (!publicMode) {
        // Reset activeAdIndex so that the appropriate ad position is shown on the seekbar
        //console.log('Reset activeAdIndex for non-publicMode');
        setActiveAdIndex(sources[sourceIndex].sectionIndex ?? 0);
      }

      if (activeAdIndex < finalAdCount && ads?.length > 0) {
        setAdMode(true);
        mixpanel.track('Started ad', { contentId, adId: ads[activeAdIndex % ads.length].id, organizationId });
      } else {
        done = true;
      }
    }

    if (done) {
      mixpanel.track('Completed audio', { contentId, organizationId });
      setActiveSectionIndex(0);
      setPosition(0);
    } else {
      handleResume();
    }
  };

  useImperativeHandle(ref, () => ({
    play: handlePlay,
    pause: handlePause,
    seek: handleSeek,
  }));

  const handleError = (event) => {
    console.error(event.target.error);
    setMessage({ children: `MediaError code ${event.target.error?.code}`, severity: 'error' });
  };

  useEffect(() => {
    if (adMode) {
      setOverrideTitle?.(ads[activeAdIndex % ads.length]?.metadata?.title);
    } else {
      setOverrideTitle?.(null);
    }
  }, [ adMode, ads, activeAdIndex, setOverrideTitle ]);

  useEffect(() => {
    const playbackRate = adMode ? 1 : playbackSpeed;

    if (audioRef.current.readyState >= 2) {
      // Set playbackRate immediately
      audioRef.current.playbackRate = playbackRate;
    } else {
      // Set playbackRate when loaded
      audioRef.current.addEventListener('loadeddata', () => {
        if (audioRef.current) {
          audioRef.current.playbackRate = playbackRate;
        }
      }, { once: true });
    }
  }, [adMode, playbackSpeed, activeSectionIndex, activeAdIndex, playing?.id]);

  //useEffect(() => console.log({ activeSectionIndex, activeAdIndex, adMode, finalAdCount }), [activeSectionIndex, activeAdIndex, adMode, finalAdCount]);

  return (
    <Stack direction="column" spacing={2} sx={sx}>

      <audio ref={audioRef}
          onError={handleError}
          onTimeUpdate={handleProgress}
          onEnded={handleEnded}
          src={adMode
              ? ads[activeAdIndex % ads.length]?.audio?.uri
              : sources.find(s => (s.sectionIndex ?? 0) === activeSectionIndex)?.uri}
          preload={(!!preload).toString()} />

      <Stack direction="column" sx={{ px: 2 }}>
        <Slider
            value={adMode
                ? adTimes[activeAdIndex]
                : position}
            size="small"
            max={duration || 1}
            step={0.1}
            valueLabelDisplay="auto"
            valueLabelFormat={renderDuration}
            marks={[
              {
                value: 0,
                label: renderDuration(position || 0),
              },
              {
                value: duration || 1,
                label: '-' + renderDuration(Math.max(duration - position, 0) || 0),
              },
            ].concat(adTimes.map(p => ({ value: p })))}
            sx={{
              '.MuiSlider-thumb': {
                transition: 'left 0.5s linear',
              },
              '.MuiSlider-track': {
                transition: 'width 0.5s linear',
              },
              '.MuiSlider-markLabel': {
                fontSize: 10,
              },
            }}
            onChange={(event) => handleSeek(event.target.value, event)} />
        <CenterBox sx={{ mt: -2 }}>
          <Typography variant="caption">
            {renderDuration((Math.max(duration - position, 0) / playbackSpeed) || 0)} left ({playbackSpeed}x)
          </Typography>
        </CenterBox>
      </Stack>

      <Stack direction="row"
          spacing={8}
          sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>

        <SimpleButton onClick={(event) => handleSeek(position - 10, event)} disabled={adMode}>
          <RewindIcon sx={{ fontSize: 32 }} />
        </SimpleButton>

        {playing?.id === id
            ? <SimpleButton onClick={handlePause}>
                <PauseIcon sx={{ fontSize: 64 }} />
              </SimpleButton>
            : <SimpleButton onClick={handlePlay}>
                <PlayIcon sx={{ fontSize: 64 }} />
              </SimpleButton>}

        <SimpleButton onClick={(event) => handleSeek(position + 10, event)} disabled={adMode}>
          <ForwardIcon sx={{ fontSize: 32 }} />
        </SimpleButton>

      </Stack>
    </Stack>
  );
});

export default AudioPlayer;
