
import { createTheme } from '@mui/material/styles';
import InterWoff2 from '../fonts/Inter.woff2';

let theme = createTheme({
  typography: {
    fontFamily: ['Inter'],
    fontSize: 12,
    proposed: {
      backgroundColor: '#FEF0C7',
      color: '#DC6803',
    },
    accepted: {
      backgroundColor: '#D1FADF',
      color: '#039855',
    },
    removed: {
      backgroundColor: '#FEE4E2',
      color: '#D92D20',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          src: local('Inter'), url(${InterWoff2}) format('woff2');
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          fontWeight: 'normal',
          textTransform: 'none',
          minWidth: 0,
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-h3': {
            fontSize: 50,
            fontWeight: 600,
          },
          '&.MuiTypography-h4': {
            fontSize: 40,
            fontWeight: 600,
          },
          '&.MuiTypography-h5': {
            fontSize: 30,
            fontWeight: 500,
          },
          '&.MuiTypography-h6': {
            fontSize: 20,
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        }
      }
    },
  },
});

// Automatically call augmentColor where needed
const augmentPalette = (palette) => Object.fromEntries(
    Object.entries(palette)
        .map(([name, color]) => {
          if (!color.light || !color.dark || !color.contrastText) {
            return [ name, theme.palette.augmentColor({ name, color }) ];
          } else {
            return [ name, color ];
          }
        }));

theme = createTheme(theme, {
  palette: augmentPalette({
    error: {
      main: '#D92D20',
      contrastText: '#FFFFFF',
    },
    darkMenu: {
      main: '#2C2C2E',
      light: '#2C2C2E',
      dark: '#2C2C2E',
      contrastText: '#FFFFFF',
    },
    black: {
      main: '#222',
      dark: '#000',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
    success: {
      main: '#039855',
    },
    purple: {
      main: '#6938EF',
    },

    // Chip colors
    completed: {
      main: '#D1FADF',
      contrastText: '#039855',
    },
    inProgress: {
      main: '#FEF0C7',
      contrastText: '#DC6803',
    },
    gray: {
      main: '#F2F2F7',
      contrastText: '#636366',
    },
    failed: {
      main: '#FEE4E2',
      contrastText: '#D92D20',
    },
  })
});

export default theme;
